<template>
  <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
    <a-form-item label="所在地区" required>
      <a-cascader v-model:value="addForm.addressArray" :options="$store.state.app.cityDict" :show-search="{ filter }"
        :changeOnSelect="true" @change="setAddress" placeholder="必须选择到区" />
    </a-form-item>
    <a-form-item class="form-bottom" v-if="addForm.addressArray != null && addForm.addressArray != ''" label="详细地址"
      required>
      <a-input style="width:87%" v-model:value="addForm.addressDetails" placeholder="例子： 四川省成都市龙泉驿区中物国际" />
      <a-button class="form-input-button" @click="onAddressSearch">搜索</a-button>
    </a-form-item>

    <a-form-item label="移动地图上标注点定位详细地址">
      <div class="map">
        <OptionalMap @onData="onData" :num="0" :city="city" :adCode="adCode" :details="addForm.addressDetails"
          ref="map" />
      </div>
    </a-form-item>

  </a-form>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import OptionalMap from '@/views/components/optionalMap'
export default {
  props: {
    cityArrayName: Array,
    addressArray: Array,
    addressDetails: Array,
    adCode: String
  },
  components: { OptionalMap },
  setup (props, context) {
    const map = ref(null)
    const state = reactive({
      addMapShow: true,
      addForm: {
        addressArray: props.addressArray,
        addressDetails: props.addressDetails
      },
      loading: false,
      adCode: props.adCode || '',
      city: props.cityArrayName
    })
    // 选择城市
    const setAddress = (value, selectedOptions) => {
      if (value.length > 2) {
        props.cityArrayName = []
        props.addressArray = []
        props.addressDetails = ''
        console.log(value, selectedOptions);
        state.adCode = selectedOptions[selectedOptions.length - 1].code
        state.addForm.addressProvince = value[0]
        state.addForm.addressCity = value[1]
        state.addForm.addressArea = value[2]
        // state.addForm.addressprovinceName = selectedOptions[0].label
        // state.addForm.addressAreaName = selectedOptions[2].label
        // state.addForm.addressCityName = selectedOptions[1].label
        if (selectedOptions[2].label == selectedOptions[1].label) state.city = selectedOptions[0].label + selectedOptions[1].label + ''
        state.city = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
        state.addMapShow = false
        //
        setTimeout(() => {
          state.addMapShow = true
          state.addForm.address = state.city
          state.addForm.lat = ''
          state.addForm.lon = ''
          state.addForm.addressDetails = ''
          state.addForm.addressCity = value[1]
          console.log('城市选择组件返回', state.addForm);
          onAddressSearch()
          // context.emit('acceptCode', state.addForm)
        }, 500)
      } else {
        state.addForm.addressArray = []
        state.addForm.addressprovinceName = null
        state.addForm.addressAreaName = null
        state.addForm.addressCityName = null
      }
    }
    // 给父组件传值
    const onData = data => {
      console.log(data);
      state.addForm.lat = data.lat
      state.addForm.lon = data.lng
      state.addForm.adCode = state.adCode
      state.addForm.address = data.address
      state.addForm.addressprovinceName = data.addressComponents.province
      state.addForm.addressCityName = data.addressComponents.city
      state.addForm.addressAreaName = data.addressComponents.district
      state.addForm.addressDetails = data.address
      if (data !== undefined) context.emit('acceptCode', state.addForm)
    }
    const onAddressSearch = () => {
      state.addMapShow = false
      setTimeout(() => {
        state.addMapShow = true
      }, 500)
      console.log(state.addForm.addressDetails);
      map.value.addressSearch(state.adCode)
    }

    const open = () => {
      state.addMapShow = true
    }
    return {
      ...toRefs(state),
      setAddress,
      onData,
      map,
      onAddressSearch,
      open
    }
  }
}
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 300px;
}

.form-input-button {
  height: 36px;
  margin-left: 10px;
}

.form-bottom {
  margin-bottom: -10px;
}
</style>
