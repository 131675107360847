import { postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/carrier/city/stronghold/page', params)
}

export function add (params) {
  return postBodyRequest('/carrier/city/stronghold/add', params)
}
export function edit (params) {
  return postBodyRequest('/carrier/city/stronghold/edit', params)
}
export function delet (id) {
  return postBodyRequest(`/carrier/city/stronghold/delete/${id}`, {})
}

export function lineAdd (lineId, params) {
  return postBodyRequest(`/carrier/city/stronghold/${lineId}/add`, params)
}

export function lineEdit (lineId, params) {
  return postBodyRequest(`/carrier/city/stronghold/${lineId}/edit`, params)
}

export function lineDetail (params) {
  return postBodyRequest('/carrier/city/stronghold/getDetails', params)
}

export function linePage (lineId, params) {
  return postBodyRequest(`/carrier/city/stronghold/${lineId}/page`, params)
}

