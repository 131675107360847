<template>
  <div>
    <HxTable v-model:columns="columns" :reload="loadData">
      <template #search>
        <a-form :model="searchForm" layout="inline">
          <a-form-item label="">
            <a-cascader v-model:value="searchForm.start" :options="$store.state.app.PClist" :changeOnSelect="true"
              @change="setStartAddress" :show-search="{ filter }" placeholder="选择城市" />
          </a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
              :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
          </a-space>
        </a-form>
      </template>
      <template #toolbarLeft>
        <a-space>
          <a-button @click="addShow = true">
            新增
          </a-button>
        </a-space>
      </template>
      <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #operation="{ record }">
          <a-space>
            <a @click="onEditShow(record)">编辑</a>
            <!--            <a @click="onLineDetailShow(record)">查看线路</a>-->
          </a-space>
        </template>
      </a-table>
    </HxTable>

    <a-modal :width="750" title="新增地址" @change="onModalNo" :confirm-loading="editLoading" @ok="onModalAddOk"
      v-model:visible="addShow">
      <Address v-if="addShow" @acceptCode='acceptCode' ref="addRef" />
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="别名">
          <a-input v-model:value="addForm.name" placeholder="请取该地址别名" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model:value="addForm.remark" placeholder="备注" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-if="editShow" :width="750" title="编辑地址" :confirm-loading="editLoading" @change="onModalNo"
      @ok="onModalEditOk" v-model:visible="editShow">
      <AddressChoose :editForm="editForm" @acceptCode='acceptEditCode' />
      <!-- <Address :cityArrayName="editForm.cityArrayName" :adCode="editForm.adCode" :addressArray="editForm.addressArray"
        :addressDetails="editForm.strongholdAddress" @acceptCode='acceptEditCode' ref="addRef" />
      <a-form layout="vertical" ref="formEditRef" :model="editForm" :rules="rules">
        <a-form-item label="别名">
          <a-input v-model:value="editForm.name" placeholder="请取该地址别名" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model:value="editForm.remark" placeholder="备注" />
        </a-form-item>
      </a-form> -->
    </a-modal>

    <a-drawer title="查询线路" placement="right" :closable="false" width='80%' v-model:visible="lineDetailShow">
      <LineType v-if="lineDetailShow" :startingAddress="startingAddress" :lineId="id"> </LineType>
    </a-drawer>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'

import HxTable from '@/components/HxTable'
import { page, add, edit, delet, lineAdd } from '@/api/carrier/optimalLineSame'
import Address from '@/views/components/address/stronghold'
import AddressChoose from './addressChoose.vue'
import { configCustomer } from '@/api/BEnd/applet'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import LineType from './lineType'
export default {
  components: {
    HxTable,
    Address,
    LineType,
    AddressChoose
  },
  setup () {
    const store = useStore()
    const state = reactive({
      id: '',
      loading: false,
      editLoading: false,
      lineDetailShow: false,
      addShow: false,
      editShow: false,
      detailShow: false,
      typeShow: false,
      disabled: false,
      listData: [],
      lineCheckboxArray: [],
      searchForm: {},
      typeForm: {
        title: '',
      },
      typeFromEnum: 0,
      editForm: {
      },
      detailForm: {},
      startingAddress: [],
      cityList: [],
      addForm: { start: [] },
      columns: [
        {
          title: '城市',
          align: 'center',
          dataIndex: 'strongholdCityName',
          width: "15%"
        },
        {
          title: '集散地',
          dataIndex: 'strongholdAddress',
          align: 'center'
        },
        {
          title: '别名',
          dataIndex: 'name',
          align: 'center',
          width: "10%"
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          align: 'center',
          width: "8%"
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '操作',
          fixed: 'right',
          align: 'center',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          },
          width: "7%"
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
      }
    })

    const formRef = ref()
    const formEditRef = ref()
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })

    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    // 接受返回值
    const acceptCode = data => {
      console.log('新增返回值', data);
      if (data) {
        // state.addForm.
        state.addForm.strongholdProvince = data.addressProvince
        state.addForm.strongholdCity = data.addressCity
        state.addForm.strongholdArea = data.addressArea
        state.addForm.strongholdProvinceName = data.addressprovinceName
        state.addForm.strongholdCityName = data.addressCityName
        state.addForm.strongholdAreaName = data.addressAreaName
        state.addForm.strongholdAddress = data.addressDetails
        state.addForm.name = data.name
        state.addForm.lat = data.lat
        state.addForm.lng = data.lon
      }
    }
    // 接受返回值
    const acceptEditCode = data => {
      console.log('编辑', data);
      if (data) {
        // state.addForm.
        state.editForm.adCode = data.adCode
        state.editForm.strongholdProvince = data.addressProvince
        state.editForm.strongholdCity = data.addressCity
        state.editForm.name = data.name
        state.editForm.remark = data.remark
        state.editForm.strongholdArea = data.addressArea
        state.editForm.strongholdProvinceName = data.addressprovinceName
        state.editForm.strongholdCityName = data.addressCityName
        state.editForm.strongholdAreaName = data.addressAreaName
        state.editForm.strongholdAddress = data.addressDetails
        state.editForm.lat = data.lat
        state.editForm.lng = data.lon
        state.editForm.addressArray = [state.editForm.strongholdProvince, state.editForm.strongholdCity, state.editForm.strongholdArea]
        if (state.editForm.strongholdArea === undefined || state.editForm.strongholdArea === '') {
          state.editForm.strongholdProvince = data.addressArray[0]
          state.editForm.strongholdCity = data.addressArray[1]
          state.editForm.strongholdArea = data.addressArray[2]
        }
      }
    }
    // 关闭消息框
    const onModalNo = () => {
      state.addShow = false
      state.addForm = {}
      state.editShow = false
      state.editForm = {}
      loadData()
    }
    // 新增确认
    const onModalAddOk = () => {
      formRef.value.validate().then(() => {
        if (state.addForm.strongholdArea === undefined || state.addForm.strongholdArea === '') {
          message.error("请在地图上搜索定点")
          return
        }
        state.editLoading = true
        add(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            state.addShow = false
            state.addForm = {}
          }
        }).finally(() => {
          state.editLoading = false
        })
      })
    }
    // 修改确认
    const onModalEditOk = () => {
      if (state.editForm.strongholdArea === undefined || state.editForm.strongholdArea === '') {
        message.error("请在地图上搜索定点")
        return
      }
      state.editLoading = true
      console.log(state.editForm);
      edit(state.editForm).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
          state.editShow = false
          state.addForm = {}
        }
      }).finally(() => {
        state.editLoading = false
      })
    }
    // 打开编辑
    const onEditShow = (r) => {
      state.editForm = r
      state.editForm.addressArray = ['' + r.strongholdProvince, '' + r.strongholdCity, '' + r.strongholdArea]
      state.editForm.cityArrayName = r.strongholdProvinceName + r.strongholdCityName + r.strongholdAreaName
      state.editShow = true
    }
    // 打开查看
    const onLineDetailShow = (r) => {
      state.id = r.id
      state.lineDetailShow = true
      onCityList(r.strongholdProvince, r.strongholdCity)
    }

    const setStartAddress = (value, selectedOptions) => {
      if (selectedOptions.length === 0) state.searchForm.strongholdCity = ''
      if (selectedOptions.length > 1) state.searchForm.strongholdCity = value[1]
    }

    // 遍历城市获取区
    const onCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityDict
      }
      state.startingAddress = []
      state.cityList.forEach(e => {
        if (Number(e.value) === Number(p)) {
          e.children.forEach(d => {
            if (Number(d.value) === Number(c)) {
              state.startingAddress = state.startingAddress.concat(d.children)
            }
          })
        }
      })
      console.log(" state.startingAddress", state.startingAddress, state.cityList)
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange,
      acceptCode,
      onModalNo,
      onEditShow,
      onLineDetailShow,
      onModalAddOk,
      onModalEditOk,
      acceptEditCode,
      setStartAddress,
      formRef,
      formEditRef,
      onCityList,

    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
