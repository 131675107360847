<template>
  <div>
    <a-form layout="vertical" ref="formRef" :model="formData" :rules="rules">
      <a-form-item label="所在地区" required>
        <a-cascader v-model:value="formData.addressArray" :options="$store.state.app.cityDict" :show-search="{ filter }"
          :changeOnSelect="true" @change="setAddress" placeholder="必须选择到区" />
      </a-form-item>
      <a-form-item v-if="formData.addressArray != null && formData.addressArray != ''" label="详细地址" required>
        <div style="display:flex">
          <a-input style="" v-model:value="formData.addressDetails" placeholder="例子： 四川省成都市龙泉驿区中物国际" />
          <a-button class="form-input-button" @click="onAddressSearch">搜索</a-button>
        </div>
      </a-form-item>
      <a-form-item label="">
        <div style="width:100%;height:300px">
          <OptionalMap @onData="onData" :num="0" :city="city" :adCode="adCode" :details="formData.addressDetails"
            ref="mapRef" />
        </div>
      </a-form-item>
      <a-form-item label="别名">
        <a-input v-model:value="formData.name" @blur="nameBlur" placeholder="请取该地址别名" />
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="formData.remark" @blur="remarkBlur" placeholder="备注" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import OptionalMap from '@/views/components/optionalMap'

export default {
  props: {
    editForm: Object
  },
  components: {
    OptionalMap
  },
  setup (props, context) {
    const mapRef = ref(null)
    const state = reactive({
      adCode: props.editForm.adCode || '',
      city: props.editForm.cityArrayName || '',
      formData: {
        addressArray: props.editForm.addressArray,
        addressDetails: props.editForm.strongholdAddress,
        name: props.editForm.name,
        remark: props.editForm.remark
      }
    })
    // 选择城市
    const setAddress = (value, selectedOptions) => {
      if (value.length > 2) {
        state.adCode = selectedOptions[selectedOptions.length - 1].code
        state.formData.addressProvince = value[0]
        state.formData.addressCity = value[1]
        state.formData.addressArea = value[2]
        state.formData.addressprovinceName = selectedOptions[0].label
        state.formData.addressAreaName = selectedOptions[2].label
        state.formData.addressCityName = selectedOptions[1].label
        if (selectedOptions[2].label == selectedOptions[1].label) state.city = selectedOptions[0].label + selectedOptions[1].label + ''
        state.city = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
        state.formData.cityArrayName = state.city
        state.addMapShow = false
        state.formData.addressDetails = ''
        //
        setTimeout(() => {
        //   state.addMapShow = true
        //   state.formData.address = state.city
        //   state.formData.lat = ''
        //   state.formData.lon = ''
        //   state.formData.addressCity = value[1]
        //   console.log('城市选择组件返回', state.formData);
        onAddressSearch()
        //   context.emit('acceptCode', state.formData)
        }, 100)
      } else {
        state.formData.addressArray = []
        state.formData.addressprovinceName = null
        state.formData.addressAreaName = null
        state.formData.addressCityName = null
      }
    }
    const onData = data => {
      console.log('接收地图回传数据', data);
      state.formData.lat = data.lat
      state.formData.lon = data.lng
      // state.formData.adCode = state.adCode
      state.formData.address = data.address
      state.formData.addressprovinceName = data.addressComponents.province
      state.formData.addressCityName = data.addressComponents.city
      state.formData.addressAreaName = data.addressComponents.district
      state.formData.addressDetails = data.address
      if (data !== undefined) context.emit('acceptCode', state.formData)
    }
    const onAddressSearch = () => {
      state.addMapShow = false
      setTimeout(() => {
        state.addMapShow = true
      }, 500)
      mapRef.value.addressSearch(state.adCode)
    }
    const nameBlur = () => {
      context.emit('acceptCode', state.formData)
    }
    const remarkBlur = () => {
      context.emit('acceptCode', state.formData)
    }
    return {
      ...toRefs(state),
      mapRef,
      onData,
      nameBlur,
      remarkBlur,
      setAddress,
      onAddressSearch
    }
  }
}
</script>

<style lang="less" scoped></style>