<template>
  <div>
    <HxTable v-model:columns="columns" :reload="loadData">
      <template #toolbarLeft>
        <a-space>
          <a-button @click="onTypeSho( '',1)">
            新增
          </a-button>
        </a-space>
      </template>
      <a-table size="small" :columns="columns" :row-key="record => record.id"
               :data-source="listData" bordered
               :pagination="pagination"
               :loading="loading" @change="handleTableChange">
        <template #operation="{record}">
          <a-space>
            <a @click="onTypeSho( record ,2)">编辑</a>
            <a @click="onTypeSho(record ,3)">查看</a>
          </a-space>
        </template>
      </a-table>
    </HxTable>
    <a-modal v-model:visible="typeShow" footer="" width="700px" :destroyOnClose="true">
      <template #title>
        <span style="font-size:20px">{{title}}</span>
      </template>
      <a-form layout="vertical" ref="typeFormRef" :model="typeForm"  :rules="typeRules">
        <a-form-item label="报价类型" :name="['lineOffer','value']">
          <a-select v-model:value="typeForm.lineOffer.value" :options="$store.state.enumAll.lineOfferType"  :disabled="disabled" />
        </a-form-item>
        <a-form-item label="运输类型" :name="['transportType','value']">
          <a-select v-model:value="typeForm.transportType.value" :options="$store.state.enumAll.transportType"  :disabled="disabled" />
        </a-form-item>
        <a-form-item label="一口价" name="cost"  v-if="typeForm.lineOffer != null && typeForm.lineOffer.value===1" >
          <a-input :disabled="disabled" v-model:value="typeForm.cost" type="number" :min="1" placeholder="请填写一口价" />
        </a-form-item>
        <a-form-item label="公式价" name="startingPrice"  v-if="typeForm.lineOffer != null && typeForm.lineOffer.value===2"  >
            <a-input :disabled="disabled"  v-model:value="typeForm.startingPrice" type="number" :min="1" suffix="起步" placeholder="请填写起步价" />
        </a-form-item>
        <a-form-item  name="exceedingKm"  v-if="typeForm.lineOffer != null && typeForm.lineOffer.value===2"  >
          <a-input :disabled="disabled"  v-model:value="typeForm.exceedingKm" type="number" prefix="超出"  suffix="公里" :min="1" placeholder="请填写超出公里" />
        </a-form-item>
        <a-form-item  name="perKmPrice"  v-if="typeForm.lineOffer != null && typeForm.lineOffer.value===2"  >
          <a-input :disabled="disabled"  v-model:value="typeForm.perKmPrice" type="number" prefix="每公里" suffix="元" :min="1" placeholder="请填写超出的梅公里，多少元" />
        </a-form-item>
        <a-form-item label=""  v-if="typeForm.lineOffer.value!=''">
          <a-checkbox-group v-model:value="lineCheckboxArray">
            <a-row :gutter="[24,12]">
              <a-col :span="8" v-for="(item,index) in startingAddress" :key="index">
                <view style="display: flex;">
                  <a-checkbox :disabled="disabled" :value="item.value">{{item.label}}</a-checkbox>
                  <a-input v-if="typeForm.lineOffer != null && typeForm.lineOffer.value===1 && lineCheckboxArray.indexOf(item.value)!= -1" :disabled="disabled" style=" width: 40%;" size="small" compact v-model:value="item.cost" type="number" :min="1" placeholder="请填写公式价" />
                </view>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
      <div v-if="!disabled" style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="typeShow=false">取消</a-button>
        <a-button size="large" class="typeForm-button" @click="onFormType">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import HxTable from '@/components/HxTable'
import { linePage, lineAdd, lineEdit ,lineDetail} from '@/api/carrier/optimalLineSame'
import { message } from 'ant-design-vue'
import { list } from '@/api/system/employee'
export default {
  props: {
    lineId: {
      type: String
    },
    startingAddress: {
      type: Array
    }
  },
  components: {
    HxTable
  },
  setup(props) {
    const state = reactive({
      lineId:'',
      title:'',
      loading: false,
      typeShow: false,
      disabled: false,
      listData: [],
      lineCheckboxArray: [],
      typeForm: {
        transportType:{},
        lineOffer:{},
        title:'',
        lineId:''
      },
      typeFromEnum:0,
      startingAddress: [],
      cityList: [],
      columns: [
        {
          title: '报价类型',
          dataIndex: 'lineOffer.label',
          align: 'center'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          align: 'center',
        },
        {
          title: '超出公里',
          dataIndex: 'perKmPrice',
          align: 'center'
        },
        {
          title: '起步价',
          dataIndex: 'startingPrice',
          align: 'center'
        },
        {
          title: '超出公里',
          dataIndex: 'exceedingKm',
          align: 'center'
        },
        {
          title: '每公里价格',
          dataIndex: 'perKmPrice',
          align: 'center'
        },
        {
          title: '报价',
          dataIndex: 'cost',
          align: 'center'
        },
        {
          title: '区域',
          dataIndex: 'areaNames',
          align: 'center'
        },
        {
          title: '操作',
          fixed: 'right',
          align: 'center',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      typeRules: {
        transportType: { value: [{ required: true, message: '请选择运输类型', trigger: 'change', type: 'number' }] },
        lineOffer: { value: [{ required: true, message: '请选择报价类型', trigger: 'change', type: 'number' }] },
        cost: { required: true, message: '请填写一口价'},
        startingPrice: { required: true, message: '请填写起步价' },
        exceedingKm: { required: true, message: '请填写超出公里' },
        perKmPrice: { required: true, message: '请填写超出的梅公里，多少元' }
      }
    })
    const typeFormRef = ref()
    const loadData = () => {
      state.loading = true
      linePage(state.lineId,{
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })

    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    onMounted(() => {
      if (props.lineId) state.lineId = props.lineId
      if (props.startingAddress) {
        state.startingAddress = props.startingAddress
      }
      loadData()
    })

    // 展示线路编辑
    const onTypeSho = (e , type )=> {
      state.typeFromEnum = type
      typeFormEmpty()
      switch (state.typeFromEnum) {
        case 1:
          state.title="新增"
          state.disabled=false
          break
        case 2:
          state.title="修改"
          state.disabled=false
          state.typeForm= e
          onTypeDetail()
          break
        case 3:
          state.title="查看"
          state.disabled=true
          state.typeForm= e
          onTypeDetail()
            break
        default:
          message.error("没有这个类型")
          return
          break
      }

      state.typeShow = true
    }

    // 表单确认
    const onFormType = () => {

      typeFormRef.value.validate().then(() => {

         let  subLines = []
          state.lineCheckboxArray.forEach(e => {
            if (e != null) {
              state.startingAddress.forEach(address=>{
                  if (Number(e)===Number(address.value)){
                    const data = {
                      cost: address.cost,
                      strongholdArea: address.value,
                      strongholdAreaName: address.label
                    }
                    subLines.push(data)
                  }
              })
            }
          })
          state.typeForm.subLines =  [...new Set(subLines)]
          if (state.typeFromEnum===1){
            onTypeAdd()
          }
          if (state.typeFromEnum===2){
            onTypeEdit()
          }
      })
    }
    // 新增线路区
    const onTypeAdd = () => {
      lineAdd(state.lineId,state.typeForm).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
          typeFormEmpty()
          state.typeShow = false
        }
      })
    }
    // 修改线路区
    const onTypeEdit = () => {
      lineEdit(state.lineId,state.typeForm).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
          typeFormEmpty()
          state.typeShow = false
        }
      })
    }
    const onTypeDetail = () => {
      state.typeForm.lineId= state.lineId
      lineDetail(state.typeForm).then(res => {
        if (res.code === 10000) {
          state.typeForm =res.data
          state.lineCheckboxArray = []
          state.startingAddress.forEach(e => {
            if (res.data.subLines!=null){
              for (let i = 0; i < res.data.subLines.length; i++) {
                if (e.value === res.data.subLines[i].strongholdArea+'') {
                  state.lineCheckboxArray.push(e.value)
                  e.cost=res.data.subLines[i].cost
                }
              }
            }
          })
        }
      })
    }
    const typeFormEmpty = ()=> {
      state.typeForm= {}
      state.lineCheckboxArray= []
      state.typeForm.transportType={
        value: "",
        label: ""
      }
      state.typeForm.lineOffer={
        value: "",
        label: ""
      }
      state.startingAddress.forEach(e => {
          e.cost = ''
      })
    }
    return {
      ...toRefs(state),
      loadData,
      handleTableChange,
      onTypeSho,
      typeFormRef,
      onTypeAdd,
      onTypeEdit,
      onFormType,
      onTypeDetail,
      typeFormEmpty
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
